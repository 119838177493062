import React, {useState} from "react"
import arrowImg from "../../images/partner/arrow1.svg"
import {Link, navigate} from "gatsby"
import Modali, {useModali} from "../modal";
import ReactGA from "react-ga";
import arrow1Icom from "../../images/partner/arrow1.svg"

export default function Hero({page}) {
  const [confirmationModal, toggleConfirmationModal] = useModali()
  const [selectedOption, handleSelectedOption] = useState("");
  const goSignup = e => {
    ReactGA.event({
      category: 'Partner Page_Find out more_Button',
      action: 'Partner Page_Find out more_Button clear'
    });
    navigate("/signup/")
  }

  const onSignUpClick = () => {
    ReactGA.event({
      category: 'Partner Page_Sign up_Green button',
      action: 'Partner Page_Sign up_Green button clicked'
    });
    toggleConfirmationModal();
  }
  return (
    // <div className="hero partner-banner text-white text-center sm:text-left">
    <div className="hero text-white text-center sm:text-left">
      <div className="container sm:px-10">
        <div className="title text-2xl sm:text-40 font-bold sm:leading-47">
          Partner Success Program
        </div>
        <div className="desc max-w-lg text-17 leading-5 sm:leading-6 mt-3 ">
          Join the LUCA Plus Partner Success Program to foster business growth for you and your clients
        </div>

        <div className="flex btn-wrap">
          {/*<button*/}
          {/*  onClick={onSignUpClick}*/}
          {/*  className="bg-mind rounded partner-but*/}
          {/* sm:pr-12 focus:outline-none mt-8 leading-5 relative flex items-center mr-5 sm:ml-0"*/}
          {/*>*/}
          {/*  Sign Up*/}
          {/*  <img*/}
          {/*    src={arrowImg}*/}
          {/*    className="hidden sm:block ml-3"*/}
          {/*  />*/}
          {/*</button>*/}
          <Link
              to={`/signup/`}
              state={{ isFindOut: true, partner: true }}
          >
            <button
                onClick={goSignup}
                className="bg-mind rounded partner-but sm:pr-12 focus:outline-none mt-8 leading-5 relative flex items-center sm:ml-0"
                // className="text-white rounded find-out-but sm:pr-12 focus:outline-none mt-8 leading-5 relative
                // flex items-center sm:ml-0"
            >
              Find Out More
              <img src={arrow1Icom} className="ml-2" />
            </button>
          </Link>
          <button
              onClick={onSignUpClick}
              className="bg-mind rounded partner-but
             sm:pl-12 focus:outline-none mt-8 leading-5 relative flex items-center ml-3"
            >
              Partner Signup
              {/*<img*/}
              {/*  src={arrowImg}*/}
              {/*  className="hidden sm:block ml-3"*/}
              {/*/>*/}
            </button>
        </div>
      </div>
      {/*<Modali.Modal {...confirmationModal} className=" error-modal">*/}
      {/*    <div className="pt-12 pop-up">*/}
      {/*      <div className="pop-up-inner mx-auto">*/}
      {/*        <div className=" text-neutral-3 mt-4">*/}
      {/*          Are you an existing XERO, MYOB or QuickBooks user?*/}
      {/*        </div>*/}
      {/*        <div className="mt-8 mb-20">*/}
      {/*          <Link*/}
      {/*              to={`/sign-up/`}*/}
      {/*              state={{ isTrial: true, plan: "partner", price: 0, page: page }}*/}
      {/*          >*/}
      {/*            <input*/}
      {/*                className="option cursor-pointer"*/}
      {/*                type="radio"*/}
      {/*                value="yes"*/}
      {/*                name="option"*/}
      {/*                checked={selectedOption === "yes"}*/}
      {/*                onClick={() => handleSelectedOption("yes")}*/}
      {/*            />*/}
      {/*            <label className="ml-2" htmlFor="option-1">*/}
      {/*              Yes*/}
      {/*            </label>*/}
      {/*          </Link>*/}
      {/*          <Link*/}
      {/*              to={`/sign-up/`}*/}
      {/*              state={{ isTrial: true, partner: true,  page: page, plan: "solo" }}*/}
      {/*              className="ml-12"*/}
      {/*          >*/}
      {/*            <input*/}
      {/*                className="cursor-pointer"*/}
      {/*                type="radio"*/}
      {/*                value="no"*/}
      {/*                name="option"*/}
      {/*                checked={selectedOption === "no"}*/}
      {/*                onClick={() => handleSelectedOption("no")}*/}
      {/*            />*/}
      {/*            <label className="ml-2" htmlFor="option-1">*/}
      {/*              No*/}
      {/*            </label>*/}
      {/*          </Link>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*</Modali.Modal>*/}
      <Modali.Modal {...confirmationModal} className=" error-modal exist-org-modal" >
        <div className="pt-4">
          <div className="pop-up-inner mx-auto">
            <div className=" text-neutral-3 mt-4">

            </div>
            <div className="mt-8 mb-12 flex flex-col">
              <Link
                to={`/sign-up?plan=combined`}
                state={{ isTrial: true, plan: "combined", price: 0 }}
                className="popup-box cursor-pointer"
              >
                <input
                  className="option cursor-pointer"
                  type="radio"
                  value="xero"
                  name="option"
                  checked={selectedOption === "xero"}
                  onClick={() => handleSelectedOption("xero")}
                />
                <label className="ml-5 w-10/12" htmlFor="option-1">
                  Signup as partner and Link My XERO MYOB or QBO
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner`}
                state={{ isTrial: true, plan: "partner", price: 0}}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5" htmlFor="option-1">
                  Signup as partner without Linking software
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner`}
                state={{ isTrial: true, plan: "partner", price: 0 }}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5" htmlFor="option-1">
                  Don’t have software. Signup up as partner
                </label>
              </Link>
            </div>
          </div>
        </div>
      </Modali.Modal>
    </div>
  )
}
