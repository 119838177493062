import React from "react"
import topRightVectorIcon from "../../images/partner/top-right-vector.svg"

export default function Number() {
  return (
    <div className="number ">
      <div className="container flex flex-col sm:flex-row justify-between sm:items-start items-center">
        <div className="block">
          <div className="title t1">
            $76B
            <img src={topRightVectorIcon} />
          </div>
          <div className="desc">
            unpaid invoices owed to <br /> Australian businesses
          </div>
        </div>
        <div className="block  head">
          <div className="title t2">
            2M
            <img src={topRightVectorIcon} />
          </div>
          <div className="desc">
            businesses drowning in a <br />sea of unpaid bills
          </div>
        </div>
        <div className="block  head">
          <div className="title t3">
            $38,000
            <img src={topRightVectorIcon} />
          </div>
          <div className="desc">
            average disputes owed <br/> to businesses
          </div>
        </div>
        <div className="block head">
          <div className="title t4">
            40%
            <img src={topRightVectorIcon} />
          </div>
          <div className="desc">
            rate of business failures <br /> due to poor cash flow
          </div>
        </div>
        <div className="block head">
          <div className="title t5">
            $29.27
            <img src={topRightVectorIcon} />
          </div>
          <div className="desc">
            The average cost to <br/> process a PDF Invoice
          </div>
        </div>
      </div>
    </div>
  )
}
