import React from "react"
import ReactGA from "react-ga";
import checkIcon from "../../images/partner/check.svg"
import w1Icon from "../../images/partner/w-1.svg"
import w2Icon from "../../images/partner/w-2.svg"
import w3Icon from "../../images/partner/w-3.svg"
export default function Why({ toggleVideoModal }) {
  const findOutMoreClick = () => {
    ReactGA.event({
      category: `Partner Page_Find out more_Green button`,
      action: `Partner Page_Find out more_Green button clicked`
    });
  }
  return (
    <div className="why">
      <div className="container flex flex-col md:flex-row justify-around items-center">
        <div className="left">
          {/*<div className="fvideo cursor-pointer mx-auto  w-11/12 xl:w-full" onClick={toggleVideoModal}>*/}
          {/*  <div className="fvideo__play"/>*/}
          {/*  <img src={videoImage} alt="video"/>*/}
          {/*</div>*/}
          <div className="text-center mt-0 sm:mt-8 md:mt-0 sm:text-left sm:ml-16 xl:ml-0">
            <div className="title text-xl sm:text-4xl mt-3 sm:mt-0  font-bold">
              Why Partner with LUCA Plus
            </div>
            <div className="text-neutral-3 text-16 leading-6 desc3 mt-4 text-left sm:w-auto mx-0">
              We provide you and your clients with an all-in-one cash flow management tool and accounting automation via our:
            </div>
            <div className="flex mb-4 mt-8 text-neutral-3 sm:items-center items-start text-left desc">
              <img src={checkIcon} className="mr-2" />
              B2B Smart Payment Platform
            </div>
            <div className="flex text-neutral-3 sm:items-center items-start text-left desc">
              <img src={checkIcon} className="mr-2" />
              AR/AP Automation (FREE for our partners)
            </div>
            <div className="mt-8 desc4 text-neutral-3 text-left">
              enabling better cash flow, efficiency, and, most importantly,
              an aligned reward incentive program to help you sign up your business and onboard your clients.
            </div>
            <div className="mt-8 w-100 sm:w-auto">
              <a
                onClick={findOutMoreClick}
                href="/signup/"
                className="find-but bg-mind text-white rounded focus:outline-none hover:bg-mint"
              >
                Find Out More
              </a>
            </div>
          </div>
        </div>
        <div className="right">
          <img src={w1Icon} className="ml-12 -mb-12" />
          <img src={w2Icon} />
          <img src={w3Icon} className="ml-12 -mt-12" />
        </div>
      </div>
    </div>
  )
}
